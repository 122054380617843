const COLOURS = {
  "sustainable-proteins": [
    {
      title: "Inactive",
      color: "#000000",
      zero: true,
      threshold: 0,
    },
    {
      title: "Reactive",
      color: "#17092c",
      zero: false,
      threshold: 0.1,
    },
    {
      title: "Active",
      color: "#1c6870",
      zero: false,
      threshold: 29,
    },
    {
      title: "Proactive",
      color: "#00c2b9",
      zero: false,
      threshold: 51,
    },
    {
      title: "Pioneer",
      color: "#847efb",
      zero: false,
      threshold: 73,
    },
  ],
  "protein-diversification": [],
  "protein-producer-index": [
    {
      title: "High Risk",
      color: "#ff0000",
      zero: false,
      threshold: 0,
    },
    {
      title: "Medium Risk",
      color: "#ff8200",
      zero: false,
      threshold: 30,
    },
    {
      title: "Low Risk",
      color: "#e6b800",
      zero: false,
      threshold: 60,
    },
    {
      title: "Best Practice",
      color: "#5cb54f",
      zero: false,
      threshold: 90,
    },
    {
      title: "No Information",
      color: "#ececec",
      zero: true,
    },
  ],
  "working-conditions": [
    {
      title: "Did Not Find",
      abbr: "DNF",
      color: "#7b1400",
      threshold: 0,
      zero: true,
    },
    {
      title: "Low",
      color: "#ff0000",
      threshold: 0.1,
      zero: false,
    },
    {
      title: "Partial",
      color: "#ff8200",
      threshold: 31,
      zero: false,
    },
    {
      title: "Good",
      color: "#e6b800",
      threshold: 60,
      zero: false,
    },
    {
      title: "Lead",
      color: "#5cb54f",
      threshold: 91,
      zero: false,
    },
  ],
  "animal-pharma": [
    {
      title: "Inactive",
      color: "#7b1400",
      threshold: 0,
      zero: true,
    },
    {
      title: "Low",
      color: "#ff0000",
      threshold: 0.1,
      zero: false,
    },
    {
      title: "Partial",
      color: "#ff8200",
      threshold: 31,
      zero: false,
    },
    {
      title: "Good",
      color: "#e6b800",
      threshold: 60,
      zero: false,
    },
    {
      title: "Lead",
      color: "#5cb54f",
      threshold: 91,
      zero: false,
    },
  ],
  "antibiotics-stewardship": [
    {
      title: "N/A",
      color: "#1f7978",
      threshold: 0,
      zero: false,
    },
  ],
  "meat-sourcing": [
    {
      title: "No",
      color: "transparent",
      threshold: 0,
      zero: true,
    },
    {
      title: "Partial",
      color: "transparent",
      threshold: 1,
      zero: false,
    },
    {
      title: "Yes",
      color: "transparent",
      threshold: 50,
      zero: false,
    },
  ],
  "restaurant-antibiotics": [
    {
      title: "NRD",
      color: "#A80404",
      threshold: 0,
      zero: true,
    },
    {
      title: "Low",
      color: "#FF465E",
      threshold: 0.1,
      zero: false,
    },
    {
      title: "Partial",
      color: "#FA9227",
      threshold: 31,
      zero: false,
    },
    {
      title: "Good",
      color: "#FFC000",
      threshold: 60,
      zero: false,
    },
    {
      title: "Lead",
      color: "#64C954",
      threshold: 91,
      zero: false,
    },
    {
      title: "N/A",
      color: "#7F7F7F",
      threshold: 0,
      zero: false,
    },
  ],
  "waste-pollution": [
    {
      title: "Poor",
      color: "#ff642b",
      threshold: 0,
      zero: true,
    },
    {
      title: "Medium",
      color: "#ff962a",
      threshold: 33.33,
      zero: false,
    },
    {
      title: "Good",
      color: "#ffba00",
      threshold: 55.55,
      zero: false,
    },
    {
      title: "Best Practice",
      color: "#63c954",
      threshold: 88.88,
      zero: false,
    },
  ],
  "seafood-traceability": [],
};

export class Colours {
  private project: keyof typeof COLOURS;

  constructor(project: string | undefined) {
    if (
      project
      && ((projectKey: string): projectKey is keyof typeof COLOURS =>
        Object.prototype.hasOwnProperty.call(COLOURS, project))(project)
    ) {
      this.project = project;
    } else {
      throw new Error(`Unknown project ${project ?? ""}`);
    }
  }

  public addAlpha(color: string, opacity: number) {
    const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);

    return color + _opacity.toString(16).toUpperCase();
  }

  public riskColour(score: number, max: number) {
    const active = this.colorLookup(score, max);

    return active?.color ? active.color : "#000000";
  }

  public backgroundColour(score: number, max: number) {
    const active = this.colorLookup(score, max);

    return {
      "background-color": active?.color ? active.color : "#ececec",
      "border-color": active?.color ? active.color : "#ececec",
    };
  }

  public level(score: number, max: number) {
    const active = this.colorLookup(score, max);

    return active?.title ? active.title : " - ";
  }

  public colourByEngagement(key: string) {
    return COLOURS[key as keyof typeof COLOURS];
  }

  public colorLookup(score: number, max: number) {
    const percentage = (Math.round(score) / max) * 100;

    const engagement = COLOURS[this.project].slice();

    const sorted = engagement.length
      ? engagement.sort((a, b) => (b.threshold ?? 0) - (a.threshold ?? 0))
      : [];

    const over = sorted.length
      ? sorted.filter((c) => percentage >= (c.threshold ?? 0))
      : [];

    const active = over.length ? over[0] : null;

    return active;
  }

  public colorByLevel(level: string) {
    return COLOURS[this.project].find((c) => c.title === level)?.color ?? "";
  }
}

export const addAlpha = (color: string, opacity: number) => {
  const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);

  return color + _opacity.toString(16).toUpperCase();
};
